import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Nav, Footer } from '@bitcoin-portal/bitcoincom-universal';
import {
  Wrapper,
  HeadSection,
  FaqSection,
  FaqContentBlock,
  FaqTitle,
  FaqSecondTitle,
  FaqSecondTitleSub,
  FaqLinkText,
  FaqLink,
  FaqFlexContainer,
  FaqCard,
  CardTitle,
  CardDesc,
  FaqTable,
  FaqTr,
  FaqTd,
  LabelTd,
  Td,
  FaqTableTitle,
  Check,
  NotCheck,
} from './styled';
import SEO from './SEO';

const Faq = ({ locale, intl: { messages } }) => (
  <>
    <SEO />
    <Wrapper>
      <HeadSection>
        <Nav locale={locale} contrast />
      </HeadSection>
      <FaqSection>
        <FaqContentBlock>
          <FaqTitle>
            <FormattedMessage id="faq.header.title" />
          </FaqTitle>
          <FaqLinkText>
            <FaqLink href="/">
              <FormattedMessage id="faq.headerlink.home" />
            </FaqLink>{' '}
            / <FormattedMessage id="faq.headerlink.faq" />
          </FaqLinkText>
          <FaqSecondTitle>
            <FormattedMessage id="faq.header.titleTwo" />
          </FaqSecondTitle>
          <FaqSecondTitleSub>
            <FormattedMessage id="faq.header.subtitle" />
          </FaqSecondTitleSub>
        </FaqContentBlock>
        <FaqContentBlock>
          <FaqFlexContainer columns={3}>
            <FaqCard padded centered>
              <CardTitle>
                <FormattedMessage id="faq.card.titles.what" />
              </CardTitle>
              <CardDesc>
                <FormattedMessage id="faq.card.descs.what.one" />
              </CardDesc>
              <CardDesc>
                <FormattedMessage id="faq.card.descs.what.two" />
              </CardDesc>
              <CardDesc>
                <FormattedMessage id="faq.card.descs.what.three" />
              </CardDesc>
            </FaqCard>
            <FaqCard padded centered>
              <CardTitle>
                <FormattedMessage id="faq.card.titles.how" />
              </CardTitle>
              <CardDesc>
                <FormattedMessage id="faq.card.descs.how.one" />
              </CardDesc>
              <CardDesc>
                <FormattedMessage id="faq.card.descs.how.two" />
              </CardDesc>
              <CardDesc>
                <FormattedMessage id="faq.card.descs.how.three" />
              </CardDesc>
            </FaqCard>
            <FaqCard padded centered>
              <CardTitle>
                <FormattedMessage id="faq.card.titles.why" />
              </CardTitle>
              <CardDesc>
                <FormattedMessage id="faq.card.descs.why.one" />
              </CardDesc>
              <CardDesc>
                <FormattedMessage id="faq.card.descs.why.two" />
              </CardDesc>
              <CardDesc>
                <FormattedMessage id="faq.card.descs.why.three" />
              </CardDesc>
            </FaqCard>
          </FaqFlexContainer>
          <FaqSecondTitle style={{ paddingTop: '24px' }}>
            <FormattedMessage id="faq.header.titleThree" />
          </FaqSecondTitle>
          <FaqSecondTitleSub>
            <FormattedMessage id="faq.header.subtitleThree" />
          </FaqSecondTitleSub>
          <FaqTableTitle>
            <FormattedMessage id="faq.tables.one.title" />
          </FaqTableTitle>
          <FaqTable>
            <FaqTr>
              <FaqTd>
                <FormattedMessage id="faq.tables.features" />
              </FaqTd>
              <FaqTd>
                <FormattedMessage id="faq.tables.one.comp" />
              </FaqTd>
              <FaqTd>
                <FormattedMessage id="faq.tables.notary" />
              </FaqTd>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.one.one" />
              </LabelTd>
              <Td>
                <Check size={24} />
              </Td>
              <Td>
                <NotCheck size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.one.two" />
              </LabelTd>
              <Td>
                <Check size={24} />
              </Td>
              <Td>
                <NotCheck size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.one.three" />
              </LabelTd>
              <Td>
                <NotCheck size={24} />
              </Td>
              <Td>
                <Check size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.one.four" />
              </LabelTd>
              <Td>
                <NotCheck size={24} />
              </Td>
              <Td>
                <Check size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.one.five" />
              </LabelTd>
              <Td>
                <NotCheck size={24} />
              </Td>
              <Td>
                <Check size={24} />
              </Td>
            </FaqTr>
          </FaqTable>
          <FaqSecondTitle>
            <FormattedMessage id="faq.header.titleFour" />
          </FaqSecondTitle>
          <FaqSecondTitleSub>
            <FormattedMessage id="faq.header.subtitleFour" />
          </FaqSecondTitleSub>
          <FaqTableTitle>
            <FormattedMessage id="faq.tables.two.title" />
          </FaqTableTitle>
          <FaqTable>
            <FaqTr>
              <FaqTd>
                <FormattedMessage id="faq.tables.features" />
              </FaqTd>
              <FaqTd>
                <FormattedMessage id="faq.tables.two.comp" />
              </FaqTd>
              <FaqTd>
                <FormattedMessage id="faq.tables.notary" />
              </FaqTd>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.two.one" />
              </LabelTd>
              <Td>
                <Check size={24} />
              </Td>
              <Td>
                <NotCheck size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.two.two" />
              </LabelTd>
              <Td>
                <Check size={24} />
              </Td>
              <Td>
                <NotCheck size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.two.three" />
              </LabelTd>
              <Td>
                <Check size={24} />
              </Td>
              <Td>
                <NotCheck size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.two.four" />
              </LabelTd>
              <Td>
                <NotCheck size={24} />
              </Td>
              <Td>
                <Check size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.two.five" />
              </LabelTd>
              <Td>
                <NotCheck size={24} />
              </Td>
              <Td>
                <Check size={24} />
              </Td>
            </FaqTr>
          </FaqTable>
          <FaqSecondTitle>
            <FormattedMessage id="faq.header.titleFive" />
          </FaqSecondTitle>
          <FaqSecondTitleSub>
            <FormattedMessage id="faq.header.subtitleFive" />
          </FaqSecondTitleSub>
          <FaqTableTitle>
            <FormattedMessage id="faq.tables.three.title" />
          </FaqTableTitle>
          <FaqTable>
            <FaqTr>
              <FaqTd>
                <FormattedMessage id="faq.tables.features" />
              </FaqTd>
              <FaqTd>
                <FormattedMessage id="faq.tables.three.comp" />
              </FaqTd>
              <FaqTd>
                <FormattedMessage id="faq.tables.notary" />
              </FaqTd>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.three.one" />
              </LabelTd>
              <Td>
                <Check size={24} />
              </Td>
              <Td>
                <NotCheck size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.three.two" />
              </LabelTd>
              <Td>
                <Check size={24} />
              </Td>
              <Td>
                <NotCheck size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.three.three" />
              </LabelTd>
              <Td>
                <Check size={24} />
              </Td>
              <Td>
                <NotCheck size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.three.four" />
              </LabelTd>
              <Td>
                <NotCheck size={24} />
              </Td>
              <Td>
                <Check size={24} />
              </Td>
            </FaqTr>
            <FaqTr>
              <LabelTd>
                <FormattedMessage id="faq.tables.three.five" />
              </LabelTd>
              <Td>
                <NotCheck size={24} />
              </Td>
              <Td>
                <Check size={24} />
              </Td>
            </FaqTr>
          </FaqTable>
          <FaqSecondTitle>
            <FormattedMessage id="faq.header.titleSix" />
          </FaqSecondTitle>
          <FaqSecondTitleSub>
            <FormattedMessage id="faq.header.subtitleSix" />
          </FaqSecondTitleSub>
        </FaqContentBlock>
      </FaqSection>
      <Footer locale={locale} />
    </Wrapper>
  </>
);

Faq.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.object,
  }).isRequired,
};
export default injectIntl(Faq);
