import styled from 'styled-components';
import {
  Section,
  ContentBlock,
  H1,
  H2,
  H3,
  H5,
  Card,
  Paragraph,
  Link,
  FlexContainer,
  CheckSolid,
  TimesSolidThick,
} from '@bitcoin-portal/bitcoincom-pkg-components';
import { colors, media } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const FaqSection = styled(Section)`
  background-color: ${colors.solid.zircon};
`;
export const FaqContentBlock = styled(ContentBlock)``;
export const FaqFlexContainer = styled(FlexContainer)`
  justify-content: center;
`;
export const FaqCard = styled(Card)``;
export const CardDesc = styled(Paragraph)`
  text-align: left;
`;
export const CardTitle = styled(H3)`
  color: ${colors.solid.caribbeanGreen};
`;

export const FaqTableTitle = styled(H5)`
  text-align: center;
`;

export const FaqTable = styled.table`
  margin: auto;
  margin-bottom: 24px;
`;
export const FaqTd = styled.td`
  font-weight: bold;
  width: auto;
  padding: 0 12px;
`;
export const LabelTd = styled.td``;
export const Td = styled.td`
  width: auto;
  text-align: center;
`;
export const Check = styled(CheckSolid)`
  color: ${colors.solid.caribbeanGreen};
`;
export const NotCheck = styled(TimesSolidThick)`
  color: ${colors.solid.bunker};
`;
export const FaqTr = styled.tr``;

export const FaqTitle = styled(H1)``;
export const FaqSecondTitle = styled(H2)``;
export const FaqSecondTitleSub = styled(Paragraph)``;
export const FaqLink = styled(Link)``;
export const FaqLinkText = styled(Paragraph)`
  text-align: left;
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.background.contrast};
`;

export const HeadSection = styled.div`
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const CustomContentBlock = styled(ContentBlock)`
  background: ${({ theme }) => theme.palette.background.contrast};
  & > div > div:first-child > img {
    max-height: 300px;
  }

  & h1 {
    ${({ theme }) => theme.palette.primary.text};
  }

  ${media.md`
    & > div {
      justify-content: space-between;
    }

    & > div > div {
      max-width: 46%;
    }

    & > div > div:first-child > img {
      max-height: 400px;
    }
  `};
`;

export const Logo = styled.img`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
`;

export const FileSection = styled(Section)`
  background-color: ${colors.solid.zircon};
`;
export const FileContentBlock = styled(ContentBlock)`
  text-align: center;
  padding: 32px 0;
`;
export const QrContentBlock = styled(ContentBlock)`
  text-align: center;
`;
